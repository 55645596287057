import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		$('header').affix({
			offset: {
				top: 100
			}
		});

	},

	methods: {

		navbarToggle: function () {

			var nav = this.$el;

			var classNames = nav.className;

			if (classNames.indexOf('opened') > -1) {
				nav.className = classNames.split('opened').join('').replace(/  +/g, ' ');
			} else {
				nav.className = classNames + ' opened';
			}
			/*
						if (nav.className === "vo-nav-bar affix-top") {
							nav.className += " opened";
						} else {
							nav.className = "vo-nav-bar";
						} */


		},
		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURI(this.name);

			}
		}



	}
};